export default {
  // CUSTOMER AUTHENTICATION
  CUSTOMER_SIGNUP_REQUEST: {
    url: "/api/v1/customer-auth/signup/request/{mobileNumber}",
    method: "get",
  },
  CUSTOMER_SIGNUP_VERIFY: {
    url: "/api/v1/customer-auth/signup/verify",
    method: "post",
  },
  CUSTOMER_SIGNUP_SUBMIT: {
    url: "/api/v1/customer/signup/complete",
    method: "post",
  },
  CUSTOMER_SIGNIN_REQUEST: {
    url: "/api/v1/customer-auth/signin/request/{mobileNumber}",
    method: "get",
  },
  CUSTOMER_SIGNIN_VERIFY: {
    url: "/api/v1/customer-auth/signin/verify",
    method: "post",
  },
  CUSTOMER_GENERATE_VERIFY_EMAIL: {
    url: "/api/v1/customer-auth/generate/{email}",
    method: "post",
  },
  CUSTOMER_VERIFY_EMAIL_WITH_TOKEN: {
    url: "/api/v1/customer-auth/verify/{token}",
    method: "post",
  },

  // CUSTOMER
  GET_CUSTOMER: {
    url: "/api/v1/customer/me",
    method: "get",
  },
  CUSTOMER_PASSWORD_RESET: {
    url: "/api/v1/customer/change",
    method: "put",
  },
  CUSTOMER_GENERATE_TEMPORARY_PASSWORD: {
    url: "/api/v1/customer/reset/{email}",
    method: "put",
  },
  CUSTOMER_LOGOUT: {
    url: "/api/v1/customer/logout",
    method: "post",
  },
  //
  SAVE_CUSTOMER_INCOME_DETAILS: {
    url: "/api/v1/customer/income-details",
    method: "post",
  },
  UPDATE_CUSTOMER_INCOME_DETAILS: {
    url: "/api/v1/customer/update-income-details",
    method: "put",
  },
  GET_CUSTOMER_OVERALL_INFO: {
    url: "/api/v1/customer/overall-info/{customerId}",
    method: "get",
  },
  GET_ALL_REGISTERED_USERS: {
    url: "/api/v1/customer",
    method: "get",
  },
  CUSTOMER_SELF_PROFILE_UPDATE: {
    url: "/api/v1/customer/edit",
    method: "put",
  },
  ADMIN_UPDATE_CUSTOMER_PROFILE: {
    url: "/api/v1/customer/{customerId}",
    method: "put",
  },
  DELETE_CUSTOMER: {
    url: "/api/v1/customer/{customerId}",
    method: "delete",
  },

  // CUSTOMER KYC
  GET_CUSTOMER_PAN_DETAILS: {
    url: "/api/v1/kyc/customer/pan/request",
    method: "post",
  },
  VERIFY_CUSTOMER_PAN: {
    url: "/api/v1/kyc/customer/pan/verify",
    method: "post",
  },
  GET_CUSTOMER_AADHAR_DETAILS: {
    url: "/api/v1/kyc/customer/aadhar/request",
    method: "post",
  },
  VERIFY_CUSTOMER_AADHAR: {
    url: "/api/v1/kyc/customer/aadhar/verify",
    method: "post",
  },
  VERIFY_CUSTOMER_AADHAR_MANUALLY: {
    url: "/api/v1/kyc/customer/aadhar/bypass",
    method: "post",
  },
  GET_CUSTOMER_AADHAR_INFO: {
    url: "/api/v1/customer/aadhar-info",
    method: "get",
  },
  ADD_CUSTOMER_ADDRESS_INFO: {
    url: "/api/v1/kyc/customer/address",
    method: "post",
  },
  UPDATE_CUSTOMER_ADDRESS_INFO: {
    url: "/api/v1/kyc/customer/update-address/{addressId}",
    method: "put",
  },
  UPLOAD_CUSTOMER_DOCS: {
    url: "/api/v1/kyc/customer/doc-upload",
    method: "put",
  },
  UPDATE_CUSTOMER_DOCS: {
    url: "/api/v1/kyc/customer/update-docs",
    method: "put",
  },

  // APPLICATIONS
  ADD_CUSTOMER_APPLICATION: {
    url: "/api/v1/customer/applications",
    method: "post",
  },
  GET_CUSTOMER_APPLICATIONS: {
    url: "/api/v1/customer/applications",
    method: "get",
  },
  GET_CUSTOMER_APPLICATION_BY_ID: {
    url: "/api/v1/accounts/application/{applicationId}",
    method: "get",
  },
  UPDATE_CUSTOMER_APPLICATION_BY_ID: {
    url: "/api/v1/applications/{applicationId}",
    method: "put",
  },
  DELETE_CUSTOMER_APPLICATION_BY_ID: {
    url: "/api/v1/applications/{applicationId}",
    method: "delete",
  },
  //
  ADD_APPLICATION_PAYSLIP: {
    url: "/api/v1/customer/applications/payslip",
    method: "post",
  },
  ADD_APPLICATION_BANK_STATEMENT: {
    url: "/api/v1/customer/applications/bank-statement",
    method: "post",
  },
  ADD_APPLICATION_BSA: {
    url: "/api/v1/loan-bgv/bsa-signzy/upload/{applicationId}",
    method: "post",
  },
  ADD_APPLICATION_QUERY_RESPONSE: {
    url: "/api/v1/application/queries/response",
    method: "post",
  },
  GET_CUSTOMER_APPLICATION_JOURNEY_BY_ID: {
    url: "/api/v1/customer/applications/journey/{applicationId}",
    method: "get",
  },
  CUSTOMER_ACCEPT_SANCTIONED_APPLICATION: {
    url: "/api/v1/customer/applications/accept/{applicationId}",
    method: "put",
  },
  CUSTOMER_REJECT_SANCTIONED_APPLICATION: {
    url: "/api/v1/customer/applications/reject/{applicationId}",
    method: "put",
  },
  GET_CUSTOMER_SINGLE_APPLICATION: {
    url: "/api/v1/customer/applications/{applicationId}",
    method: "get",
  },
  GET_CUSTOMER_SANCTIONED_APPLICATION: {
    url: "/api/v1/customer/applications/sanctioned/{applicationId}",
    method: "get",
  },
  GET_CUSTOMER_POST_SANCTIONED_APPLICATION: {
    url: "/api/v1/customer/applications/post-sanction/{applicationId}",
    method: "get",
  },
  ADD_CUSTOMER_PERMANENT_ADDRESS_INFO: {
    url: "/api/v1/customer/applications/address/{applicationId}",
    method: "post",
  },
  ADD_CUSTOMER_REFERENCE_DETAILS: {
    url: "/api/v1/customer/applications/reference-details/{applicationId}",
    method: "post",
  },
  ADD_CUSTOMER_ACCOUNT_DETAILS: {
    url: "/api/v1/customer/applications/account-details/{applicationId}",
    method: "post",
  },
  ADD_CUSTOMER_ADDITIONAL_DOCS: {
    url: "/api/v1/customer/applications/additional-docs/{applicationId}",
    method: "post",
  },
};
